<template>
  <div class="mobile-page app">
    <MobileHeader />
    <!-- <MobileFooter /> -->
    <router-view></router-view>
  </div>
</template>

<script>
import MobileHeader from "../components/MobileHeader";
import MobileFooter from "../components/MobileFooter";
export default {
  name: "MobileContainer",
  components: {
    MobileHeader,
    MobileFooter
  },
};
</script>

<style lang="scss">
.mfooter-padding {
  padding-bottom: 80px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000042;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
  padding: 0 25px;
}
</style>

<style lang="scss" scoped>
.mobile-page {
  padding-top: 60px;
}
</style>