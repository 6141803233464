<template>
  <header class="header-back">
    <div class="d-flex align-items-center header-profile">
      <img src="/new-assets/icon/user-light.svg" alt="">
      <div>
        <p>משתמש</p>
        <p>123456</p>
      </div>
    </div>
    <img src="/new-assets/img/icon.png" alt="" class="header-logo">
    <img src="/new-assets/icon/bars-light-2.svg" alt="" 
      class="menu-icon" 
      @click="showTopMenu = !showTopMenu"
    >
    <div class="menu-block" v-if="showTopMenu">
      <div class="menu-item">הודעות</div>
      <div class="menu-item active">הזמנות אחרונות</div>
      <div class="menu-item">חשבוניות וקבלות</div>
      <div class="menu-item">אנשי קשר</div>
      <div class="menu-item">איזור אישי</div>
    </div>
  </header>
</template>

<script>
  export default {
    name: "MobileHeader",
    props: {
        msg: String
    },
    methods: {
    },
    data() {
      return {
        showTopMenu: false
      }
    },
    computed: {},
    created() {}
  };
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000042;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  .header-profile {
    position: absolute;
    left: 10px;
    direction: ltr;
    img {
      margin: 0 10px;
    }
    p {
      font-size: 14px;
    }
  }
  .menu-icon {
    position: absolute;
    right: 20px;
  }
}
.menu-block {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 60px;
  border-top: 1px solid #00000042;
  box-shadow: 0px 20px 20px #00000042;
  padding: 10px 0 20px;
  .menu-item {
    padding: 10px 20px;
    color: #234395;
    font-weight: 700;
    &.active {
      background-color: #eeeeee;
    }
  }
}
</style>