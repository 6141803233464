<template>
  <footer>
      <div>
        <p>תאריך אספקה</p>
        <p><b>09.07.2020</b></p>
      </div>
      <img src="/new-assets/icon/pencil-alt-light.svg" alt="">
  </footer>
</template>

<script>
  export default {
    name: "MobileHeader",
    props: {
        msg: String
    },
    methods: {
    },
    computed: {},
    created() {}
  };
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000042;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
  padding: 0 25px;
}
</style>